<template>
  <div>
    <div class="q-pa-md text-center full-height">
      <div class="text-h4 text-light-blue-8 q-pb-xl">
        <div v-if="$store.state.currentUser">
          Halo {{ $store.state.currentUser.name }},
        </div>
        Selamat Datang di PPDB Online 2022-2023
      </div>
    </div>
    <div class="text-left" style>
      <q-expansion-item
        expand-separator
        default-opened
        label="PROSEDUR PENDAFTARAN"
        popup
        header-class="bg-light-blue-5 text-white text-weight-bold"
      >
        <q-card class="text-subtitle1">
          <q-card-section class="bg-grey-1">
            <ol>
              <li>
                Mulai pendaftaran online dengan klik menu "Daftar" di menu utama
              </li>
              <li>
                Anda akan diminta mengisi form pendaftaran awal :
                <ul>
                  <li>Nama siswa baru</li>
                  <li>Email aktif, 1 email untuk 1 siswa</li>
                  <li>Nomer HP</li>
                </ul>
              </li>
              <li>
                Setelah pendaftaran awal berhasil, dalam 5-10 menit anda akan
                menerima email yang berisi :
                <ul>
                  <li>Username dan password untuk login</li>
                  <li>Prosedur transfer biaya pendaftaran</li>
                </ul>
              </li>
              <li>
                Transfer biaya pendaftaran untuk dapat mengisi formulir
                pendaftaran :
                <ul>
                  <li>Dapat dilakukan via teller, ATM, m-banking, e-banking</li>
                  <li>
                    Pastikan rekening dan penerima sesuai informasi di poin 3
                  </li>
                  <li>
                    Pastikan nominal transfer persis (misal: Rp 100.021,-)
                  </li>
                  <li>Foto bukti transfer untuk di upload (poin 6)</li>
                </ul>
              </li>
              <li>
                Pilih menu "Login", gunakan username dan password pada poin 3
              </li>
              <li>
                Pada bagian bawah halaman ini, klik tombol "Upload Bukti
                Transfer" (verifikasi dilakukan maksimal 1 x 24 jam)
              </li>
              <li>
                Setelah verifikasi selesai, anda dapat klik tombol "Isi Form
                Pendaftaran" di bagian bawah halaman ini
              </li>
              <li>
                Mulai isi formulir pendaftaran (Pastikan data yang diisikan
                adalah benar dan jujur)
              </li>
              <li>
                Download dan cetak formulir pendaftaran pada menu Download
                Formulir
              </li>
              <li>Bawa formulir tercetak pada saat proses daftar ulang</li>
              <li>Panitia melakukan verifikasi berkas calon peserta didik</li>
              <li>
                Mengikuti Observaasi dan Wawancara oleh panitia sesuai dengan
                jadwal yang sudah ditentukan
              </li>
              <li>Selamat datang di SMP Al Furqon Boarding School</li>
              <div class="q-pa-md text-subtitle2 text-black">
                *gunakan email yang berbeda jika sudah pernah mendaftarkan
                emailnya di website https://smpafbs.sch.id/
              </div>
            </ol>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section class="text-center">
            <div class="q-py-xl text-subtitle1 text-grey-8">
              <a v-if="!sudahIsiForm"
                >Persiapkan data dan berkas yang diperlukan, silahkan isi dengan
                data yang benar</a
              >
              <a v-else>
                Anda sudah berhasil mengisi form pendaftaran, download file
                berikut lalu print untuk diserahkan di waktu daftar ulang
              </a>

              <div class="q-pa-md">
                <q-btn
                  v-if="!$store.state.currentUser"
                  color="light-blue-8"
                  @click="startForm"
                  label="Daftar"
                />
                <q-btn
                  v-else-if="sudahIsiForm"
                  color="light-blue-8"
                  @click="startForm"
                  label="Download Formulir"
                />
                <q-btn
                  v-else
                  color="light-blue-8"
                  @click="startForm"
                  label="Isi Form Pendaftaran"
                />
              </div>
              <div class="q-pa-md">
                <q-btn
                  v-if="$store.state.currentUser"
                  flat
                  no-caps
                  @click="uploadForm"
                  color="light-blue-6"
                  label="Upload Bukti Transfer"
                />
                <q-btn
                  flat
                  no-caps
                  type="a"
                  :href="'./data/Biaya-SMP-AFBS-2020.pdf'"
                  target="_blank"
                  color="light-blue-6"
                  label="Download Biaya Masuk.pdf"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </div>

    <q-dialog
      v-model="showForm"
      maximized
      persistent
      transition-show="slide-left"
      transition-hide="slide-down"
    >
      <q-card class="bg-grey-3">
        <div style="width: 100%">
          <q-linear-progress
            size="15px"
            :value="progress1 * step"
            color="warning"
          >
            <div class="absolute-full flex flex-start">
              <q-badge
                color="warning"
                text-color="white"
                :label="progressLabel1"
              />
            </div>
          </q-linear-progress>
        </div>
        <div v-if="step == 1" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            Data Siswa
          </div>
          <div class>
            <div class="q-pa-md q-ma-sm bg-white q-mb-lg">
              <div class="text-center text-subtitle1 q-pb-md">Data Pribadi</div>
              <label for="nama">Nama</label>
              <q-input dense outlined ref="nama" v-model="newData.nama" />

              <label for="alias">Nama Panggilan</label>
              <q-input
                dense
                outlined
                ref="alias"
                class
                v-model="newData.alias"
              />

              <label for="noid">Kewarganegaraan</label>
              <q-btn-group unelevated spread class="q-mb-md">
                <q-btn
                  no-caps
                  @click="newData.nation = 'WNI'"
                  :outline="newData.nation == 'WNI' ? false : true"
                  color="light-blue-5"
                  label="WNI"
                />
                <q-btn
                  no-caps
                  @click="newData.nation = 'WNA'"
                  :outline="newData.nation == 'WNA' ? false : true"
                  color="light-blue-5"
                  label="WNA"
                />
              </q-btn-group>
              <label for="jenis_kelamin">Jenis Kelamin</label>
              <q-btn-group unelevated spread class="q-mb-md">
                <q-btn
                  no-caps
                  @click="newData.jenis_kelamin = 'L'"
                  :outline="newData.jenis_kelamin == 'L' ? false : true"
                  color="light-blue-5"
                  label="Laki-laki"
                />
                <q-btn
                  no-caps
                  @click="newData.jenis_kelamin = 'P'"
                  :outline="newData.jenis_kelamin == 'P' ? false : true"
                  color="light-blue-5"
                  label="Perempuan"
                />
              </q-btn-group>
            </div>
            <div class="q-pa-md q-ma-sm bg-white q-mb-lg">
              <div class="text-center text-subtitle1 q-pb-md">
                Data Tambahan
              </div>
              <label for="temp_lahir">Tempat Lahir</label>
              <q-input
                dense
                outlined
                ref="temp_lahir"
                v-model="newData.temp_lahir"
              />

              <label for="tgl_lahir">Tanggal Lahir</label>
              <!-- <q-input dense outlined ref="tgl_lahir" v-model="newData.tgl_lahir">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="newData.tgl_lahir" mask="YYYY-MM-DD" />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>-->
              <q-input
                dense
                outlined
                ref="rt_rw"
                v-model="newData.tgl_lahir"
                mask="##/##/####"
                fill-mask
              />

              <label for="anak_ke">Anak Ke</label>
              <q-input
                dense
                outlined
                type="number"
                ref="anak_ke"
                v-model="newData.anak_ke"
                placeholder
              />

              <label for="saudara">Jumlah Saudara Kandung</label>
              <q-input
                dense
                outlined
                type="number"
                id="saudara"
                ref="saudara"
                v-model="newData.saudara_kandung"
                placeholder
              />

              <label for="saudara">Jumlah Saudara Tiri</label>
              <q-input
                dense
                outlined
                type="number"
                id="saudara"
                ref="saudara"
                v-model="newData.saudara_tiri"
                placeholder
              />
            </div>
            <div class="q-pa-md q-ma-sm bg-white q-mb-xl">
              <div class="text-center text-subtitle1 q-pb-md">Alamat</div>
              <label for="add">Nama Jalan</label>
              <q-input
                dense
                outlined
                ref="add"
                v-model="newData.alamat"
                placeholder
              />

              <label for="rt_rw">RT / RW</label>
              <!-- <q-input
                dense
                outlined
                ref="rt_rw"
                v-model="newData.rt_rw"
                placeholder=""
                 
              />-->
              <q-input
                dense
                outlined
                ref="rt_rw"
                v-model="newData.rt_rw"
                mask="###/###"
                fill-mask
              />

              <label for="kel">Desa / Kelurahan</label>
              <q-input dense outlined ref="kel" v-model="newData.kelurahan" />

              <label for="kec">Kecamatan</label>
              <q-input dense outlined ref="kec" v-model="newData.kecamatan" />

              <label for="kab_kota">Kabupaten / Kota</label>
              <q-input
                dense
                outlined
                ref="kab_kota"
                v-model="newData.kab_kota"
              />

              <label for="provinsi">Provinsi</label>
              <q-input
                dense
                outlined
                ref="provinsi"
                v-model="newData.provinsi"
              />

              <label for="pos">Kode POS</label>
              <q-input dense outlined ref="pos" v-model="newData.kode_pos" />
            </div>
          </div>
          <div class="col-2 bottom row items-end">
            <div class="row" style="width: 100%">
              <q-btn
                outline
                class="col-6 q-pa-sm no-border-radius bg-white"
                label="Back"
                color="light-blue-8"
                @click="showForm = false"
              />
              <q-btn
                unelevated
                class="col-6 q-pa-sm no-border-radius"
                label="Next"
                color="light-blue-8"
                @click="step = 2"
              />
            </div>
          </div>
        </div>
        <div v-if="step == 2" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            Data Orang Tua
          </div>
          <div class>
            <div class="q-pa-md q-ma-sm bg-white q-mb-lg">
              <div class="text-center text-subtitle1 q-pb-md">
                Identitas Ayah
              </div>
              <label for="nama">Nama Lengkap</label>
              <q-input
                autofocus
                dense
                outlined
                ref="nama"
                v-model="newData.nama_ayah"
              />

              <label for="noid">Kewarganegaraan</label>
              <q-btn-group unelevated spread class="q-mb-md">
                <q-btn
                  no-caps
                  @click="newData.nation_ayah = 'WNI'"
                  :outline="newData.nation_ayah == 'WNI' ? false : true"
                  color="light-blue-5"
                  label="WNI"
                />
                <q-btn
                  no-caps
                  @click="newData.nation_ayah = 'WNA'"
                  :outline="newData.nation_ayah == 'WNA' ? false : true"
                  color="light-blue-5"
                  label="WNA"
                />
              </q-btn-group>

              <label for="nama">Tempat Lahir</label>
              <q-input
                dense
                outlined
                ref="nama"
                v-model="newData.temp_lahir_ayah"
              />

              <label for="tgl_lahir_ayah">Tanggal Lahir</label>
              <!-- <q-input
                dense
                outlined
                ref="tgl_lahir_ayah"
                v-model="newData.tgl_lahir_ayah"
            
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="newData.tgl_lahir_ayah" mask="YYYY-MM-DD" />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>-->
              <q-input
                dense
                outlined
                ref="rt_rw"
                v-model="newData.tgl_lahir_ayah"
                mask="##/##/####"
                fill-mask
              />

              <label for="nama">Pendidikan Terakhir</label>
              <q-input
                dense
                outlined
                ref="nama"
                v-model="newData.pend_ayah"
                placeholder
              />

              <label for="nama">Pekerjaan</label>
              <q-input
                dense
                outlined
                ref="nama"
                v-model="newData.kerja_ayah"
                placeholder
              />

              <label for="nama">No Telp. / HP</label>
              <q-input dense outlined ref="nama" v-model="newData.no_hp_ayah" />
            </div>
            <div class="q-pa-md q-ma-sm bg-white q-mb-lg">
              <div class="text-center text-subtitle1 q-pb-md">
                Identitas Ibu
              </div>
              <label for="nama">Nama Lengkap</label>
              <q-input
                dense
                outlined
                ref="nama"
                v-model="newData.nama_ibu"
                placeholder
              />

              <label for="noid">Kewarganegaraan</label>
              <q-btn-group unelevated spread class="q-mb-md">
                <q-btn
                  no-caps
                  @click="newData.nation_ibu = 'WNI'"
                  :outline="newData.nation_ibu == 'WNI' ? false : true"
                  color="light-blue-5"
                  label="WNI"
                />
                <q-btn
                  no-caps
                  @click="newData.nation_ibu = 'WNA'"
                  :outline="newData.nation_ibu == 'WNA' ? false : true"
                  color="light-blue-5"
                  label="WNA"
                />
              </q-btn-group>

              <label for="nama">Tempat Lahir</label>
              <q-input
                dense
                outlined
                ref="nama"
                v-model="newData.temp_lahir_ibu"
              />

              <label for="tgl_lahir_ibu">Tanggal Lahir</label>
              <!-- <q-input
                dense
                outlined
                ref="tgl_lahir_ibu"
                v-model="newData.tgl_lahir_ibu"
                
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="newData.tgl_lahir_ibu" mask="YYYY-MM-DD" />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>-->
              <q-input
                dense
                outlined
                ref="rt_rw"
                v-model="newData.tgl_lahir_ibu"
                mask="##/##/####"
                fill-mask
              />

              <label for="pend_ibu">Pendidikan Terakhir</label>
              <q-input
                dense
                outlined
                ref="pend_ibu"
                v-model="newData.pend_ibu"
                placeholder
              />

              <label for="kerja_ibu">Pekerjaan</label>
              <q-input
                dense
                outlined
                ref="kerja_ibu"
                v-model="newData.kerja_ibu"
              />

              <label for="no_hp_ibu">No Telp. / HP</label>
              <q-input
                dense
                outlined
                ref="no_hp_ibu"
                v-model="newData.no_hp_ibu"
              />
            </div>
            <div class="q-pa-md q-ma-sm bg-white q-mb-xl">
              <div class="text-center text-subtitle1 q-pb-md">Data Lain</div>
              <label for="email_ortu">Email Aktif</label>
              <q-input
                dense
                outlined
                ref="email_ortu"
                v-model="newData.email_ortu"
                placeholder="xxxxx@gmail.com"
              />

              <label for="pendapatan">Pendapatan Perbulan</label>
              <div>
                <q-radio
                  keep-color
                  v-model="newData.pendapatan"
                  val="Di bawah Rp 3.000.000"
                  label="Di bawah Rp 3.000.000"
                  color="light-blue-5"
                />
                <q-radio
                  keep-color
                  v-model="newData.pendapatan"
                  val="Rp 3.000.000 - Rp 6.000.000"
                  label="Rp 3.000.000 - Rp 6.000.000"
                  color="light-blue-5"
                />
                <q-radio
                  keep-color
                  v-model="newData.pendapatan"
                  val="Di atas Rp 6.000.000"
                  label="Di atas Rp 6.000.000"
                  color="light-blue-5"
                />
              </div>
            </div>
          </div>
          <div class="col-2 bottom row items-end">
            <div class="row" style="width: 100%">
              <q-btn
                outline
                class="col-6 q-pa-sm no-border-radius bg-white"
                label="Back"
                color="light-blue-8"
                @click="step = 1"
              />
              <q-btn
                unelevated
                class="col-6 q-pa-sm no-border-radius"
                label="Next"
                color="light-blue-8"
                @click="step = 3"
              />
            </div>
          </div>
        </div>
        <div v-if="step == 3" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            Data Wali Siswa
          </div>
          <div class="bg-white">
            <div class="q-pa-md q-ma-sm bg-white q-mb-xl">
              <div class="text-center text-subtitle1 q-pb-md">
                Identitas Wali
              </div>
              <label for="nama_wali">Nama Lengkap</label>
              <q-input
                dense
                outlined
                ref="nama_wali"
                v-model="newData.nama_wali"
              />

              <label for="nation_wali">Kewarganegaraan</label>
              <q-btn-group unelevated spread class="q-mb-md">
                <q-btn
                  no-caps
                  @click="newData.nation_wali = 'WNI'"
                  :outline="newData.nation_wali == 'WNI' ? false : true"
                  color="light-blue-5"
                  label="WNI"
                />
                <q-btn
                  no-caps
                  @click="newData.nation_wali = 'WNA'"
                  :outline="newData.nation_wali == 'WNA' ? false : true"
                  color="light-blue-5"
                  label="WNA"
                />
              </q-btn-group>

              <label for="temp_lahir_wali">Tempat Lahir</label>
              <q-input
                dense
                outlined
                ref="temp_lahir_wali"
                v-model="newData.temp_lahir_wali"
              />

              <label for="tgl_lahir_wali">Tanggal Lahir</label>
              <!-- <q-input
                dense
                outlined
                ref="tgl_lahir_wali"
                v-model="newData.tgl_lahir_wali"
                
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="newData.tgl_lahir_wali" mask="YYYY-MM-DD" />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>-->
              <q-input
                dense
                outlined
                ref="rt_rw"
                v-model="newData.tgl_lahir_wali"
                mask="##/##/####"
                fill-mask
              />

              <label for="pend_wali">Pendidikan Terakhir</label>
              <q-input
                dense
                outlined
                ref="pend_wali"
                v-model="newData.pend_wali"
                placeholder
              />

              <label for="kerja_wali">Pekerjaan</label>
              <q-input
                dense
                outlined
                ref="kerja_wali"
                v-model="newData.kerja_wali"
              />

              <label for="no_hp_wali">No Telp. / HP</label>
              <q-input
                dense
                outlined
                ref="no_hp_wali"
                v-model="newData.no_hp_wali"
              />

              <label for="alamat_wali">Alamat Lengkap</label>
              <q-input
                autogrow
                dense
                outlined
                ref="alamat_wali"
                v-model="newData.alamat_wali"
                placeholder
              />

              <label for="pos_wali">Kode POS</label>
              <q-input
                dense
                outlined
                ref="pos_wali"
                v-model="newData.pos_wali"
                placeholder
              />
            </div>
          </div>
          <div class="col-2 bottom row items-end">
            <div class="row" style="width: 100%">
              <q-btn
                outline
                class="col-6 q-pa-sm no-border-radius bg-white"
                label="Back"
                color="light-blue-8"
                @click="step = 2"
              />
              <q-btn
                unelevated
                class="col-6 q-pa-sm no-border-radius"
                label="Next"
                color="light-blue-8"
                @click="step = 4"
              />
            </div>
          </div>
        </div>
        <div v-if="step == 4" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            Data Periodik Siswa
          </div>
          <div class="bg-white">
            <div class="q-pa-md q-ma-sm bg-white q-mb-xl">
              <div class="text-center text-subtitle1 q-pb-md">
                Data Periodik
              </div>
              <label for="gol_darah">Golongan Darah</label>
              <q-btn-group unelevated spread class="q-mb-md">
                <q-btn
                  no-caps
                  @click="newData.gol_darah = 'A'"
                  :outline="newData.gol_darah == 'A' ? false : true"
                  color="light-blue-5"
                  label="A"
                />
                <q-btn
                  no-caps
                  @click="newData.gol_darah = 'B'"
                  :outline="newData.gol_darah == 'B' ? false : true"
                  color="light-blue-5"
                  label="B"
                />
                <q-btn
                  no-caps
                  @click="newData.gol_darah = 'O'"
                  :outline="newData.gol_darah == 'O' ? false : true"
                  color="light-blue-5"
                  label="O"
                />
                <q-btn
                  no-caps
                  @click="newData.gol_darah = 'AB'"
                  :outline="newData.gol_darah == 'AB' ? false : true"
                  color="light-blue-5"
                  label="AB"
                />
              </q-btn-group>

              <label for="tinggi_badan">Tinggi Badan (cm)</label>
              <q-input
                dense
                outlined
                type="number"
                ref="tinggi_badan"
                v-model="newData.tinggi_badan"
                placeholder
              />

              <label for="berat_badan">Berat Badan (Kg)</label>
              <q-input
                dense
                outlined
                type="number"
                ref="berat_badan"
                v-model="newData.berat_badan"
                placeholder
              />

              <label for="riwayat_penyakit"
                >Penyakit yang pernah diderita</label
              >
              <q-input
                dense
                outlined
                ref="riwayat_penyakit"
                v-model="newData.riwayat_penyakit"
                placeholder
              />

              <label for="kelainan">Kelainan jasmani / lainnya</label>
              <q-input
                dense
                outlined
                ref="kelainan"
                v-model="newData.kelainan"
                placeholder
              />
            </div>
          </div>
          <div class="col-2 bottom row items-end">
            <div class="row" style="width: 100%">
              <q-btn
                outline
                class="col-6 q-pa-sm no-border-radius bg-white"
                label="Back"
                color="light-blue-8"
                @click="step = 3"
              />
              <q-btn
                unelevated
                class="col-6 q-pa-sm no-border-radius"
                label="Next"
                color="light-blue-8"
                @click="step = 5"
              />
            </div>
          </div>
        </div>
        <div v-if="step == 5" class="form-step">
          <div class="bg-light-blue-8 q-pa-md text-subtitle1 text-white">
            Data Pendidikan Sebelumnya
          </div>
          <div class>
            <div class="q-pa-md q-ma-sm bg-white q-mb-lg">
              <div class="text-center text-subtitle1 q-pb-md">Pendidikan</div>
              <label for="alm_sekolah_asal">Nama Sekolah</label>
              <q-input
                dense
                autogrow
                for="alm_sekolah_asal"
                outlined
                v-model="newData.asal_sekolah"
              />
              <label for="alm_sekolah_asal">Alamat Sekolah</label>
              <q-input
                dense
                autogrow
                for="alm_sekolah_asal"
                outlined
                v-model="newData.alamat_sekolah_asal"
              />

              <label for="nisn">NISN</label>
              <q-input
                dense
                outlined
                ref="nisn"
                v-model="newData.nisn"
                placeholder="9900087XXX"
              />

              <label for="no_ijazah">No. Ijazah / STTB</label>
              <q-input
                dense
                outlined
                ref="no_ijazah"
                v-model="newData.no_ijazah"
                placeholder="DN-03 Dd 109XXX"
              />
            </div>
            <div class="q-pa-md q-ma-sm bg-white q-mb-xl">
              <div class="text-center text-subtitle1 q-pb-md">Prestasi</div>
              <div
                v-for="(x, i) in listPrestasi"
                :key="i"
                class="bg-white full-width q-pa-sm q-my-md shadow-1"
              >
                <div class="row justify-between">
                  <div class="col-8 text-bold">{{ x.nama_prestasi }}</div>
                  <div class="col-4 text-teal-7 text-caption text-right">
                    {{ x.bidang }}
                  </div>
                </div>
                <div class="row justify-between">
                  <div class="col-8 text-teal-7 text-caption">
                    {{ x.instansi }}
                  </div>
                  <div class="col-4 text-teal-7 text-caption text-right">
                    {{ x.tahun }}
                  </div>
                </div>
              </div>
              <div
                @click="newPrestasi = true"
                class="q-pa-sm shadow-1 text-center text-h6 text-grey full-width bg-white"
              >
                +
              </div>
            </div>
          </div>
          <div class="col-2 bottom row items-end">
            <div class="row" style="width: 100%">
              <q-btn
                outline
                class="col-6 q-pa-sm no-border-radius bg-white"
                label="Back"
                color="light-blue-8"
                @click="step = 4"
              />
              <q-btn
                unelevated
                class="col-6 q-pa-sm no-border-radius"
                label="Submit"
                color="light-blue-8"
                @click="onSubmit"
              />
            </div>
          </div>
        </div>
      </q-card>
      <q-dialog v-model="newPrestasi" persistent>
        <q-card>
          <q-card-section class="bg-light-blue-8 text-white text-center"
            >Tambah Data Prestasi</q-card-section
          >
          <q-card-section>
            <q-input
              label="Nama Prestasi"
              dense
              outlined
              v-model="prestasi.nama_prestasi"
              class="full-width"
            />
            <q-input
              label="Bidang"
              dense
              outlined
              v-model="prestasi.bidang"
              class="full-width"
            />
            <q-input
              label="Penyelenggara"
              dense
              outlined
              v-model="prestasi.instansi"
              class="full-width"
            />
            <q-input
              label="Tahun"
              dense
              outlined
              v-model="prestasi.tahun"
              class="full-width"
            />

            <div class="row justify-between">
              <q-btn
                outline
                color="light-blue-8"
                class="q-mx-sm"
                @click="
                  newPrestasi = false;
                  prestasi = {};
                "
                label="Batal"
                style="width: 100px"
                no-caps
              />
              <q-btn
                unelevated
                color="light-blue-8"
                class="q-mx-sm"
                @click="addPrestasi"
                label="Simpan"
                style="width: 100px"
                no-caps
              />
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-dialog>
    <q-dialog v-model="alert">
      <q-card class="q-pa-md">
        <q-card-section>Anda belum isi formulir pendaftaran!</q-card-section>
        <q-card-sectio class="row justify-center">
          <q-btn
            v-close-popup
            flat
            color="light-blue-6"
            @click="showForm = true"
            label="isi formulir"
          />
        </q-card-sectio>
      </q-card>
    </q-dialog>
    <q-dialog v-model="alert2">
      <q-card class="q-pa-md">
        <q-card-section
          >Anda sudah upload bukti transfer, Apakah anda akan upload
          ulang?</q-card-section
        >
        <q-card-sectio class="row justify-center">
          <q-btn
            no-caps
            v-close-popup
            flat
            color="light-blue-6"
            label="Tidak"
          />
          <q-btn
            no-caps
            v-close-popup
            flat
            color="light-blue-6"
            @click="$router.push('/pendaftaran/konfirmasi')"
            label="Ya"
          />
        </q-card-sectio>
      </q-card>
    </q-dialog>

    <q-page-container class="bg-white full-width">
      <router-view />
    </q-page-container>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      sudahIsiForm: false,
      progress1: 0.2,
      step: 1,
      showForm: false,
      newData: {
        nation: "WNI",
        nation_ayah: "WNI",
        nation_ibu: "WNI",
        nation_wali: "WNI",
        jenis_kelamin: "L",
        gol_darah: "O",
      },
      listPrestasi: [],
      prestasi: {},
      newPrestasi: false,
      alert: false,
      alert2: false,
    };
  },
  mounted() {
    this.newData.username = this.$store.state.currentUser.username;
    this.newData.email_ortu = this.$store.state.currentUser.username;
    this.cekPembayaran();

    // this.$http.get("/pendaftar_byid/1", {})
    // .then(async (result) => {
    //   this.newData = result.data
    // });
  },
  methods: {
    uploadForm() {
      this.$http
        .get(
          "/get_status_pendaftaran/" + this.$store.state.currentUser.username,
          {}
        )
        .then((result) => {
          if (!result.data.file_transfer)
            this.$router.push("/pendaftaran/konfirmasi");
          else if (result.data.status == "N") this.alert2 = true;
          else if (result.data.status == "Y") {
            let routeData = this.$router.resolve({
              name: "form-final",
              params: { nisn: result.data.nisn },
            });
            window.open(routeData.href, "_blank");
          }
        })
        .catch((err) => {
          this.alert = true;
        });
    },
    async cekPembayaran() {
      await this.$http
        .get(
          "/get_status_pendaftaran/" + this.$store.state.currentUser.username,
          {}
        )
        .then((result) => {
          this.sudahIsiForm = true;
        })
        .catch((err) => {
          this.sudahIsiForm = false;
        });
    },
    startForm() {
      if (this.$store.state.currentUser) {
        this.$http
          .get(
            "/get_status_pendaftaran/" + this.$store.state.currentUser.username,
            {}
          )
          .then((result) => {
            let routeData = this.$router.resolve({
              name: "form-final",
              params: { nisn: result.data.nisn },
            });
            window.open(routeData.href, "_blank");
          })
          .catch((err) => {
            this.showForm = true;
          });
      } else {
        this.$router.push("/buat-akun");
      }
    },
    addPrestasi() {
      this.listPrestasi.push(this.prestasi);
      this.prestasi = {};
      this.newPrestasi = false;
    },
    onSubmit() {
      this.newData.tgl_lahir = this.newData.tgl_lahir
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_ayah = this.newData.tgl_lahir_ayah
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_ibu = this.newData.tgl_lahir_ibu
        .split("/")
        .reverse()
        .join("-");
      this.newData.tgl_lahir_wali = this.newData.tgl_lahir_wali
        .split("/")
        .reverse()
        .join("-");
      this.$http.post("/pendaftar_baru", this.newData, {}).then((result) => {
        for (var i = 0; i < this.listPrestasi.length; i++) {
          this.listPrestasi[i].id_pendaftar = result.data;
          this.listPrestasi[i].nama = this.newData.nama;
          this.listPrestasi[i].nisn = this.newData.nisn;
          this.$http
            .post("/prestasi_pendaftar", this.listPrestasi[i], {})
            .then((result) => {});
        }

        // this.$http.post("/notif_pendaftaran/" + result.data + "/" + this.newData.username, {}).then((result) => {});

        this.$q.notify({
          icon: "done",
          color: "positive",
          message: "Pengisian formulir berhasil, Silahkan cek email anda",
        });
        this.cekPembayaran();
        this.step = 1;
        this.showForm = false;
        this.newData = {
          nation: "WNI",
          nation_ayah: "WNI",
          nation_ibu: "WNI",
          nation_wali: "WNI",
          jenis_kelamin: "L",
          gol_darah: "O",
        };
      });
    },
  },
  computed: {
    maxHeight() {
      return this.$q.screen.height;
    },
    progressLabel1() {
      return this.step + " of " + 5;
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #fff;
  // overflow: auto;
}

label {
  color: grey;
}
.form-step {
  overflow: auto;
  height: calc(100% - 15px);
}

.q-field__native,
.q-field__input {
  background-color: #fff;
}
.q-input,
.q-textarea {
  padding-bottom: 16px;
}
</style>
